import {useConfigurationDataStore} from '@/store/configuration-data'

export default defineNuxtRouteMiddleware(async (to, from) => {
  const configStore = useConfigurationDataStore()
  configStore.newSearch = !(from?.query?.oldsearch === '1' || to?.query?.oldsearch === '1')
  if (from?.query?.oldsearch !== undefined && to?.query?.oldsearch === undefined) {
    if (to.fullPath.includes('?')) {
      to.fullPath += `&oldsearch=${from?.query.oldsearch}`
    } else {
      to.fullPath += `?oldsearch=${from?.query.oldsearch}`
    }
    return navigateTo(to.fullPath)
  }
})
