import {createGtm} from '@gtm-support/vue-gtm'

//not working need special treatment
export default defineNuxtPlugin({
  name: 'gtm',
  async setup(nuxtApp) {
    const runtimeConfig = useRuntimeConfig()
    window.dataLayer = window.dataLayer || []

    nuxtApp.vueApp.use(
      createGtm({
        enabled: false,
        id: runtimeConfig.public.GTM_ID || 'GTM-MF4HR32',
        defer: true,
        compatibility: true,
        nonce: '2726c7f26c',
        debug: true,
        loadScript: true,
        source: 'https://sst.fst.com/gtm.js'
      })
    )
    nuxtApp.vueApp.__gtm__installed = true

    nuxtApp.provide('gtmReset', () => {
      window.dataLayer?.push(function() {
        this.reset()
      })
    })
  }
})
