/*eslint no-unused-vars: ["error", { "argsIgnorePattern": "^_" }]*/
export function generateTempId() {
  return (Math.random() * 1000000000).toFixed(0)
}

export function encodeQuery(queryObject) {
  return Object.entries(queryObject)
    .filter(([_, value]) => typeof value !== 'undefined')
    .map(
      ([key, value]) =>
        encodeURIComponent(key) +
        (value !== null ? '=' + encodeURIComponent(value) : '')
    )
    .join('&')
}

/**
 * Get property defined by dot notation in string.
 * Based on  https://github.com/dy/dotprop (MIT)
 *
 * @param  {Object} holder   Target object where to look property up
 * @param  {string} propName Dot notation, like 'this.a.b.c'
 * @return {*}          A property value
 */
export function getProp(
  holder,
  propName
) {
  if (!propName || !holder || typeof holder !== 'object') {
    return holder
  }

  if (propName in holder) {
    return holder[propName]
  }

  const propParts = Array.isArray(propName)
    ? propName
    : (propName + '').split('.')

  let result = holder
  while (propParts.length && result) {
    result = result[propParts.shift()]
  }

  return result
}

export function normalizePath(ctx, path = '') {
  // Remove query string
  let result = path.split('?')[0]

  // Remove base path
  if (ctx && ctx.base) {
    result = result.replace(ctx.base, '/')
  }

  // Remove redundant / from the end of path
  if (result.charAt(result.length - 1) === '/') {
    result = result.slice(0, -1)
  }

  // Remove duplicate slashes
  result = result.replace(/\/+/g, '/')

  return result
}

export function parseQuery(queryString) {
  const query = {}
  const pairs = queryString.split('&')
  for (let pair of pairs) {
    const pairSplit = pair.split('=')
    query[decodeURIComponent(pairSplit[0])] = decodeURIComponent(pairSplit[1] || '')
  }
  return query
}

// creates and returns a comparer function for objects
// example: [{id: 1, name: 'Zelda'}, {id: 2, name: 'Adam'}].sort(propSortComparer('name'))
export function propSortComparer(propname, caseSensitive) {
  return function(el1, el2) {
    let textA = (el1 && el1[propname]) ? el1[propname].toString() : ''
    if (textA && !caseSensitive) {
      textA = textA.toLowerCase()
    }
    let textB = (el2 && el2[propname]) ? el2[propname].toString() : ''
    if (textB && !caseSensitive) {
      textB = textB.toLowerCase()
    }

    if (textA > textB) {return 1}
    if (textB > textA) {return -1}
    return 0
  }
}

const hasParentFactory = (allCategories) => (category) => {
  return allCategories.some(c => c.Subcategories && c.Subcategories.some(sc => sc.id === category.id))
}

const getParentsFactory = (allCategories) => (category) => {
  return allCategories.filter(c => c.Subcategories && c.Subcategories.some(sc => sc.id === category.id))
}

export function getGroupedDesigns(allCategories) {
  const hasParent = hasParentFactory(allCategories)
  const getParents = getParentsFactory(allCategories)

  // find all designs with parents (unparented designs shouldn't be possible, but just in case...)
  let designs = allCategories?.filter(c => c?.IsProductDesign === true && hasParent(c))?.map(d => ({id: d.id}))

  // for every design, add parents and grandparents
  designs.forEach(d => { d.Parents = getParents(d).map(p => ({Name: p.TechnicalName, id: p.id, Parents: getParents(p).map(gp => ({Name: gp.TechnicalName}))})) })

  // build list of {grandparent}/parent/design
  let res = []
  for (let design of designs) {
    let realDesign = allCategories.find(d => d.id === design.id)
    // group by brand
    for (let parent of design.Parents) {
      if (parent.Parents.length > 0) {
        for (let grandParent of parent.Parents) {
          addDesign(res, parent, grandParent, realDesign)
        }
      } else {
        addDesign(res, parent, null, realDesign)
      }
    }
  }
  return res
}

function addDesign(res, parent, grandParent, realDesign) {
  let cat = `${parent.Name}`
  let catTitle = cat
  if (grandParent) {
    cat = `${grandParent.Name}/${parent.Name}`
    catTitle = `${grandParent.Name} / ${parent.Name}`
  }

  let overviewCategory = res.find(r => r.Category === cat)
  if (!overviewCategory) {
    overviewCategory = {Category: cat, CategoryTitle: catTitle, parent, grandParent, Designs: []}
    res.push(overviewCategory)
  }

  overviewCategory.Designs.push(realDesign)
}

export function objectify(array, field) {
  if (!array || typeof(array) !== 'object' || !Array.isArray(array) || !field) {
    return {}
  }
  return array.reduce((a, v) => { return {...a, [v[field]]: v} }, {})
}

export function promisedTimeout(ms) {
  if (!ms) {
    return
  }
  return new Promise((r) => setTimeout(r, ms))
}

export function arrayOfObjectsGroupBy(arr, property) {
  if (arr) {
    return arr.reduce(function(memo, x) {
      if (!memo[x[property]]) { memo[x[property]] = [] }
      memo[x[property]].push(x)
      return memo
    }, {})
  }
  return {}
}

export function getNextDate(date = null) {
  const today = date !== null ? date : new Date()
  let nextDay = today
  switch (today.getDay()) {
  case 6:
    nextDay = today.setDate(today.getDate() + 2)
    break
  case 0:
    nextDay = today.setDate(today.getDate() + 1)
    break
  default:
    nextDay = date !== null ? today : today.setDate(today.getDate())
  }
  return new Date(nextDay)
}

export function getMaxDate() {
  const today = new Date()
  const maxDate = today.setFullYear(today.getFullYear() + 2)
  return new Date(maxDate)
}

export function sanitizeHtml(html, document = null) {
  try {
    if (document) {
      let tempElement = document.createElement('span')
      tempElement.innerHTML = html
      return tempElement.innerText
    }
  } catch {
    // do nothing on purpose
  }

  return html
}

export function parseCookies(cookieString) {
  if (Array.isArray(cookieString)) {
    let ret = {}
    for (let c of cookieString) {
      ret = {...ret, ...parseCookies(c.split(';')[0])}
    }
    return ret
  }

  if (cookieString) {
    let regex = /(; )?(?<name>[^=]+)=(?<value>[^;]*)/g
    let match = regex.exec(cookieString)
    let ret = {}
    while (match && match.groups) {
      let {name, value} = match.groups
      ret[name] = value
      match = regex.exec(cookieString)
    }

    return ret
  }

  return {}
}

export function serializeCookie(name, value, days) {
  var expires = ''
  if (days) {
    var date = new Date()
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
    expires = '; expires=' + date.toUTCString()
  }
  return `${name}=${value || ''}${expires}; path=/`
}

export function setCookie(name, value, days) {
  document.cookie = serializeCookie(name, value, days)
}

export function isValidJwtToken(token) {
  if (!token) {
    return false
  }

  try {
    if (token.startsWith('Bearer ')) {
      token = token.split(' ')[1]
    }
    let decodedToken = Buffer.from(token.split('.')[1], 'base64').toString()
    let payload = JSON.parse(decodedToken)
    return payload.exp > Math.floor(Date.now() / 1000)
  } catch {
    return false
  }
}

export function splitFullName(fullName) {
  const [lastName, ...firstNameParts] = fullName.trim().split(' ').reverse()
  return {
    firstName: firstNameParts.reverse().join(' '),
    lastName: lastName || ''
  }
}

export function cleanUpSearchTerm(str, language = 'en') {
  if (language !== 'en') {
    if (str.split(' ').some(s => s.split(',').length > 2)) {
      return str
    }
    // Languages different from English format: '.' as thousands separator, ',' as decimal separator
    return str.replace(/(\d+)([,.])(\d*?)(0+)(\b|\s|$)/g, function(match, intPart, decimalPoint, fractionPart, trailingZeros, ending) {
      if (decimalPoint === ',') {
        if (fractionPart === '') {
          return intPart + ending // No fraction, keep only the integer part
        } else if (/[^0]/.test(fractionPart)) {
          return intPart + decimalPoint + fractionPart + ending // Keep decimal part if non-zero digits exist
        } else {
          return intPart + ending // Remove decimal part if it's all zeros
        }
      } else if (decimalPoint === '.') {
        // Keep thousands separator in German
        return match
      }
    }).replace(/(\d+),0+(\b|\s|$)/g, '$1') // Remove ',000' in the German format (if all zeros after comma)
  }

  if (language === 'en') {
    if (str.split(' ').some(s => s.split('.').length > 2)) {
      return str
    }
    // English format: ',' as thousands separator, '.' as decimal separator
    return str
      .replace(/(\d+),(?=\d{3}\b)/g, '$1,') // Keep valid thousands separators
      .replace(/(\d+)([.])(\d*?)(0+)(\b|\s|$)/g, function(match, intPart, decimalPoint, fractionPart, trailingZeros, ending) {
        // Handle decimal part in English
        if (fractionPart === '') {
          return intPart + ending // No fraction, keep only the integer part
        } else if (/[^0]/.test(fractionPart)) {
          return intPart + decimalPoint + fractionPart + ending // Keep decimal part if non-zero digits exist
        } else {
          return intPart + ending // Remove decimal part if it's all zeros
        }
      })
      .replace(/(\d+)\.000(\b|\s|$)/g, '$1') // Finally, remove '.000' from numbers like '1,000.000'
  }
}
