import axios from '@/services/axios_auth'

export const useHydraulicSelectorNAService = () => {
  const runtimeConfig = useRuntimeConfig()
  const baseurl = runtimeConfig.public.BASIC_API_URL  || 'https://apim-enext-stg.azure-api.net'

  const hydras_na_axios = axios.create({
    baseURL: runtimeConfig.public.HYDRAS_NA_API || (baseurl + '/hydraulic-selector-na-api/1.0.0/hydraulic-selector-na')
  })

  const getPresets = async (): Promise<any> => {
    return await hydras_na_axios.get('/presets')
      .then((res) => {
        return res ? res.data : []
      })
      .catch(() => {
        return []
      })
  }

  const getMetadata = async (): Promise<any> => {
    return await hydras_na_axios.get('/metadata')
      .then((res) => {
        return res ? res.data : []
      })
      .catch(() => {
        return {}
      })
  }

  const postHydraulicSelectorValues = async (requestBody: any): Promise<any> => {
    return await hydras_na_axios.post('', requestBody)
      .then((res) => {
        return res ? res.data : null
      })
      .catch(() => {
        return null
      })
  }

  return {
    getPresets,
    getMetadata,
    postHydraulicSelectorValues
  }
}
