import axios from 'axios'

let ctx = {}

import {isTokenExpired} from '../assets/js/authentication'
import {getTimestampHash} from '../services/helper/url_timestamp_helper.js'
import {initResponseInterceptor} from './interceptors'
import {FST_REQUEST_AUTH_HEADER} from '../assets/js/constants'
import {useUserStore} from '@/store/user'
import {useAuthService} from '@/composables/auth_service'

const create = function(...axiosConfig) {
  let axiosApiInstance = axios.create(...axiosConfig)
  if (process.server) {
    initResponseInterceptor(axiosApiInstance, getContext)
    axiosApiInstance.interceptors.request.use(
      async config => {
        const result = await getTimestampHash(axios.getUri(config))
        if (result && result.hash && result.timestamp) {
          if (config?.headers) {
            config.headers[FST_REQUEST_AUTH_HEADER.HASH] = result.hash
            config.headers[FST_REQUEST_AUTH_HEADER.TIMESTAMP] = result.timestamp
          }
        }
        return config
      },
      error => {
        Promise.reject(error)
      })

    return axiosApiInstance
  }

  initResponseInterceptor(axiosApiInstance, getContext)
  axiosApiInstance.interceptors.request.use(
    async config => {
      const userStore = useUserStore()
      if (userStore) {
        try {
          const token = await getToken(`${config.baseURL}${config.url}`)
          if (token) {
            if (config?.headers) {
              // eslint-disable-next-line eqeqeq
              config.headers = config?.headers['Authorization'] != null ? config?.headers : {
                ...config.headers,
                'Authorization': `Bearer ${token}`
              }
            }
          }
        } catch (e) {
          // ctx.error(e.message)
          // eslint-disable-next-line no-console
          console.error(e)
        }
      }
      // Add timestamp hash header
      const result = await getTimestampHash(axios.getUri(config))
      if (result && result.hash && result.timestamp) {
        if (config?.headers) {
          config.headers[FST_REQUEST_AUTH_HEADER.HASH] = result.hash
          config.headers[FST_REQUEST_AUTH_HEADER.TIMESTAMP] = result.timestamp
        }
      }
      return config
    },
    error => {
      Promise.reject(error)
    })

  return axiosApiInstance
}

const setContext = function(ctxInstance) {
  ctx = ctxInstance
}

const getContext = function() {
  return ctx
}

const getToken = async function(requestorUrl) {
  const userStore = useUserStore()
  let token = userStore.accessToken

  const app = useNuxtApp()
  const userManager = app.$userManager

  const user = await userManager.getUser()
  if (user && !user.expired && isTokenExpired(token)) {
    let customerUuid = userStore.loadedCustomerUuid
    if (!customerUuid || customerUuid === 'null') {
      try {
        if (app.$sentry) {
          app.$sentry.captureException(new Error(`Customer Uuid was ${customerUuid ? '(string)null' : 'null'} for url ${requestorUrl}`))
        }
      } catch {
        // nothing to do really 
      }

      customerUuid = 'default'
    }
    const {getAccessToken} = useAuthService()
    let accessToken = user.access_token
    if (!accessToken.startsWith('Bearer ') && user.token_type === 'Bearer') {
      accessToken = `${user.token_type} ${accessToken}`
    }
    const res = await getAccessToken(customerUuid, accessToken)
    if (res && res.status === 200) {
      token = res.data.accessToken
      const userStore = useUserStore()
      if (userStore && token) {
        userStore.setAccessToken(res.data.accessToken)
      }
    }
    else {
      throw Error({message: 'No AccessToken received for url ' + requestorUrl})
    }
  }
  return token ? token : null
}

export default {
  ...axios,
  create,
  setContext,
  getContext,
  getToken
}
