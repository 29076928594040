import axios from '@/services/axios_auth'
import Query from '~/assets/js/query'
import {useGtm} from '@gtm-support/vue-gtm'
import {addPrice, getPrice, getPrices, isDbUsable} from '~/assets/js/indexed_db_helper'
import {API_NAMES, CHUNK_SIZES} from '~/assets/js/constants'
import {useAddressHelper} from '@/composables/address_helper'

export const usePandaService = () => {
  const baseurl = useRuntimeConfig().public.BASIC_API_URL || 'https://apim-enext-stg.azure-api.net'
  const {getDeliveryAddressUuid} = useAddressHelper()

  const panda_axios = axios.create({
    baseURL: useRuntimeConfig().public.PANDA_API || (baseurl + '/panda-api/1.0.0'),
    name: API_NAMES.panda
  })

  async function getAvailabilities(sku, quantity, availabilityDate, plant = null, track = false) {
    let cleanSku = (typeof sku === 'string' && !isNaN(parseInt(sku))) ? parseInt(sku) : sku
    if (typeof cleanSku === 'number' && typeof quantity === 'number') {
      let where = 'quantity=' + (quantity || 1)
      const deliveryAddressUuid = getDeliveryAddressUuid()
      if (deliveryAddressUuid !== '') {
        where += ' and deliveryAddressUuid=\'' + deliveryAddressUuid + '\''
      }
      if (availabilityDate) {
        where += ' and availabilityDate=\'' + availabilityDate.toISOString() + '\''
      }
      if (plant) {
        where += ' and plant=\'' + plant + '\''
      }
      const params = new Query().where(where).toOdataParams()
      const res = await panda_axios.get(`/availabilities(${cleanSku})`, {params})
        .catch(() => {return null})
      if (track) {
        useGtm()?.trackEvent({
          event: 'request_price_availability',
          category: 'availability_request',
          action: 'get_availability',
          value: JSON.stringify({'sku': cleanSku, 'quantity': quantity})
        })
      }
      return res ? res.data : null
    }
    return null
  }

  async function getAvailabilitiesBulk(items, track = false) {
    if (Array.isArray(items) && items.length > 0) {
      const deliveryAddressUuid = getDeliveryAddressUuid()
      if (deliveryAddressUuid !== '') {
        items = items.map(i => ({...i, deliveryAddressUuid: deliveryAddressUuid}))
      }
      let availabilities = []
      const chunkSize = CHUNK_SIZES.PandaService
      let parallelApiCalls = []
      items = items.map(i => ({...i, requestId: i.requestId || getRequestId(i)}))
      for (let i = 0; i < items.length; i += chunkSize) {
        const itemsChunk = items.slice(i, i + chunkSize)
        parallelApiCalls.push(panda_axios.post('/availabilities/$bulkRequest', itemsChunk))
      }

      await Promise.all(parallelApiCalls).then(results => {
        results.forEach(res => {
          if (res && res?.data) {
            availabilities = availabilities.concat(res.data)
          }
        })
      }).catch(() => {return null})

      if (track && items.length < 25) {
        try {
          let trackObjects = items.filter(f => f?.sku && f?.quantity).map(f => ({sku: f?.sku, quantity: f?.quantity}))
          trackObjects.forEach(o => useGtm()?.trackEvent({
            event: 'request_price_availability',
            category: 'availability_request',
            action: 'get_availabilitiesBulk',
            value: JSON.stringify(o)
          }))
        } catch (_) {
          // never fail on tracking
        }
      }
      return availabilities.length > 0 ? availabilities : null
    }
    return null
  }

  async function getMaterialPrices(sku, quantity, track = false) {
    let cleanSku = (typeof sku === 'string' && !isNaN(parseInt(sku))) ? parseInt(sku) : sku
    let price = null

    if (typeof cleanSku === 'number' && typeof quantity === 'number') {
      const deliveryAddressUuid = getDeliveryAddressUuid()
      price = await getPrice(cleanSku, quantity, deliveryAddressUuid)
      price = price ? price.price : null
      if (!price) {
        let where = 'quantity=' + (quantity || 1)
        if (deliveryAddressUuid !== '') {
          where += ' and deliveryAddressUuid=\'' + deliveryAddressUuid + '\''
        }
        const params = new Query().where(where).toOdataParams()
        const res = await panda_axios.get(`/materialPrices(${cleanSku})`, {params})
          .catch(() => {return null})
        if (res) {
          price = res.data
          await addPrice(price, cleanSku, quantity, deliveryAddressUuid)
        }
        if (track) {
          try {
            useGtm()?.trackEvent({
              event: 'request_price_availability',
              category: 'price_request',
              action: 'get_price',
              value: JSON.stringify({'sku': cleanSku, quantity: quantity})
            })
          } catch (_) {
            // never fail on tracking
          }
        }
      }
    }
    return price !== '' ? price : null
  }

  async function getMaterialPricesBulk(skus, track = false, ignoreCache = false) {
    let prices = await getPrices(ignoreCache)
    let requestData = []

    if (Array.isArray(skus)) {
      const deliveryAddressUuid = getDeliveryAddressUuid()

      const chunkSize = CHUNK_SIZES.PandaService
      let allFilteredSkus = []
      let reducedPrice = prices.map(p => ({sku: p?.sku, qty: p?.qty, adressUuid: p?.adressUuid}))
      let reducedSku = skus.map(s => ({sku: s?.sku, qty: s?.quantity, adressUuid: deliveryAddressUuid}))
      let parallelApiCalls = []
      for (let i = 0; i < skus.length; i += chunkSize) {
        const skuChunk = skus.slice(i, i + chunkSize)
        if (!reducedSku.every(s => reducedPrice.some(p => p?.sku === s?.sku && p?.qty === s?.qty && p?.adressUuid === s?.adressUuid))) {
          let filteredSkus = reducedSku.filter(s => !reducedPrice.some(p => p?.sku === s?.sku && p?.qty === s?.qty && p?.adressUuid === s?.adressUuid))
          filteredSkus = skuChunk.filter(s => filteredSkus.some(p => p?.sku === s?.sku && p?.qty === s?.quantity))
          if (deliveryAddressUuid !== '') {
            filteredSkus = filteredSkus.map(i => ({...i, deliveryAddressUuid: deliveryAddressUuid}))
          }
          filteredSkus = filteredSkus.map(i => ({...i, requestId: i.requestId || getRequestId(i)}))
          allFilteredSkus = allFilteredSkus.concat(filteredSkus)

          parallelApiCalls.push(panda_axios.post('/materialPrices/$bulkRequest', filteredSkus))
        }
      }

      await Promise.all(parallelApiCalls).then(results => {
        results.forEach(res => {
          if (res && res?.data) {
            requestData = requestData.concat(res.data)
          }
        })
        return requestData
      }).catch(() => {
        return null
      })

      if (await isDbUsable()) {
        if (requestData && requestData.length > 0) {
          for (const price of requestData) {
            await addPrice(price, price?.sku, price.quantity, deliveryAddressUuid)
          }
        }

        prices = await getPrices(false)
        prices = prices.filter(p => reducedSku.some(s => s?.sku === p?.sku && s.qty === p.qty && s.adressUuid === p.adressUuid)).map(p => p.price)
      }
      else {
        prices = requestData
      }

      if (track && allFilteredSkus.length < 25) {
        try {
          allFilteredSkus.map(o => ({sku: o?.sku, quantity: o.qty})).forEach(o => {
            useGtm()?.trackEvent({
              event: 'request_price_availability',
              category: 'price_request',
              action: 'get_pricesBulk',
              value: JSON.stringify(o)
            })
          })
        } catch (_) {
          // never die on tracking error
        }
      }
    }
    return prices ? prices : null
  }

  async function getPriceScales(sku) {
    let cleanSku = (typeof sku === 'string' && !isNaN(parseInt(sku))) ? parseInt(sku) : sku
    if (typeof cleanSku === 'number') {
      const deliveryAddressUuid = getDeliveryAddressUuid()
      let where = ''
      let route = `/materialPrices(${cleanSku})/priceScales`
      if (deliveryAddressUuid !== '') {
        where = 'apply=filter(deliveryAddressUuid eq \'' + deliveryAddressUuid + '\')'
        route = `${route}?${where}`
      }
      const res = await panda_axios.get(route).catch(() => {return null})
      return res ? res.data : {priceScales: []}
    }
    return {priceScales: []}
  }

  function getRequestId(cartItem, plant) {
    let date = new Date(cartItem.availabilityDate)
    let dateWithoutHours = date.setHours(0, 0, 0, 0)
    return `${cartItem?.sku}-${cartItem?.quantity}-${cartItem?.availabilityDate ? dateWithoutHours : ''}-${plant ?? '0000'}`
  }

  return {
    getAvailabilities,
    getAvailabilitiesBulk,
    getMaterialPrices,
    getMaterialPricesBulk,
    getPriceScales,
    getRequestId
  }
}
